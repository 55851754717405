/**
 * Created by master on 06.04.2016.
 */
var mapOptions = {
	center: [46.2, 72.0],
	zoom: 5.0
};
var mapCityList = [
	{title : 'Таукент',     title_kz : 'Таукент',     coordinates: [43.863754, 68.757788]},
	{title : 'Астана',      title_kz : 'Астана',      coordinates: [51.150612, 71.347651]},
	{title : 'Бишкек',      title_kz : 'Бишкек',      coordinates: [42.802248, 74.592737]},
	{title : 'Караганда',   title_kz : 'Қарағанды',   coordinates: [49.84, 73.09]},
	{title : 'Кызылорда',   title_kz : 'Қызылорда',   coordinates: [44.83986,65.50268]},
	{title : 'Тараз',       title_kz : 'Тараз',       coordinates: [42.883094, 71.428675]},
	{title : 'Туркестан',   title_kz : 'Түркістан',   coordinates: [43.300674,68.270345]},
	{title : 'Балхаш',      title_kz : 'Балқаш',      coordinates: [46.861009, 74.971766]},
	{title : 'Шымкент',     title_kz : 'Шымкент',     coordinates: [42.314934, 69.604944]},
	{title : 'Алматы',      title_kz : 'Алматы',      coordinates: [43.269672, 76.894373]},

];
