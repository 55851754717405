/**
 * Created by master on 06.04.2016.
 */

ymaps.ready(initMap);
var routeMap;

function initMap(){

	var mapBox = document.getElementById('mapContainer');
	if (mapBox) {
		routeMap = new ymaps.Map('mapContainer', {
				center: mapOptions.center,
				zoom: mapOptions.zoom,
				type: 'yandex#map'
				//behaviors: ['ruler', 'scrollZoom']
			},
			{
				avoidFractionalZoom: false
			});

		routeMap.controls.add('zoomControl', {left:5, top:175});

		var myIconContentLayout = ymaps.templateLayoutFactory.createClass(
			'<a href="$[properties.refer]" class="rmap">$[properties.name]</a>', {
				build: function () {
					this.constructor.superclass.build.call(this);
				},
				clear: function () {
					this.constructor.superclass.clear.call(this);
				}
			});

		for (var i = 0; i < mapCityList.length; i++){

			var langPatch = '';
			var cityTitle = mapCityList[i].title;
			if (_gConfig.lang == 'kz') {
				cityTitle = mapCityList[i].title_kz;
				langPatch = '/kz';
			}

			var pointObj = new ymaps.GeoObject({
				geometry: {
					type: "Point",
					coordinates: mapCityList[i].coordinates
				},
				properties: {
					name: cityTitle,
					refer: langPatch + '/route?toLocation='+cityTitle
				}
			}, {
				iconContentLayout: myIconContentLayout,
				preset: 'twirl#blueStretchyIcon'
			});
			pointObj.events.add('click',  function (e) {
				var link = e.originalEvent.target.properties._T.refer;
				if (typeof link != undefined){
					top.location.href = link;
				}
			});
			routeMap.geoObjects.add(pointObj);
		}
	}

}